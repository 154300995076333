<template>
	<div>
		<v-layout class="py-3 px-3">
			<v-flex class="">
				<div class="fw-600 sub-heading primary--text">
					Service Provider
					<v-select
						v-if="!isDetail"
						v-model="type"
						class="listing-select"
						hide-details
						:disabled="pageLoading"
						:items="listingStatus"
						style="min-width: 274px !important; min-width: 150px; max-width: 200px"
						solo
						@change="typeFilterRows($event)"
						:menu-props="{ offsetY: true, contentClass: 'rounded-lg' }"
					>
						<template #item="{ item }">
							<div class="py-1 d-flex align-center">
								<div style="min-width: 55px">
									<v-chip class="px-2" dark :color="item.color">
										{{ item.count }}
									</v-chip>
								</div>
								<div class="fw-600 text-uppercase">{{ item.text }}</div>
							</div>
						</template>
					</v-select>
				</div>
			</v-flex>
			<v-spacer></v-spacer>
			<v-flex class="d-flex justify-content-end">
				<v-btn
					v-if="!isDetail"
					class="white--text"
					dense
					tile
					@click="addServiceProviderDialog = true"
					color="red darken-4"
				>
					<v-icon class="mr-1" left> mdi-plus</v-icon> Add Service Provider
				</v-btn>

				<div v-if="!isDetail" class="d-flex justify-end mb-1">
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template #activator="{ on, attrs }">
							<v-btn
								color="blue darken-4"
								class="ml-2 text-white"
								tile
								min-height="26"
								x-small
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-table-edit</v-icon>
							</v-btn>
						</template>

						<Draggable
							tag="ul"
							v-model="draggableThead"
							class="draggable-group"
							handle=".draggable-drag-icon"
							v-on:change="updateTable('ssl')"
						>
							<template v-for="cols in draggableThead">
								<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
									<v-checkbox
										dense
										v-model="draggableTheadShow"
										v-bind:value="cols.key"
										:label="cols.name"
										:disabled="cols?.fixed || pageLoading"
										color="blue"
										hide-details
										class="mt-0 mb-0"
										v-on:change="updateTableVisiblity('ssl')"
									></v-checkbox>
									<v-icon
										v-if="!pageLoading && !cols?.fixed"
										class="draggable-action draggable-drag-icon"
										right
										color="blue"
										>mdi-drag</v-icon
									>
									<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
								</li>
							</template>
						</Draggable>
					</v-menu>
				</div>

				<v-btn class="ml-3" dense tile v-on:click="goBack()">
					<v-icon class="ml-1">mdi-arrow-left</v-icon>
					Back
				</v-btn>
			</v-flex>
		</v-layout>
		<div class="px-3">
			<template v-if="isDetail">
				<ServiceProviderDetail />
			</template>
			<template v-else>
				<template v-if="pageLoading">
					<div class="mx-20 my-40 text-center">
						<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
					</div>
				</template>
				<div v-else class="v-data-table bt-table theme--light">
					<div class="v-data-table__wrapper table_height" style="height: calc(100vh - 269px)">
						<table class="w-100">
							<thead>
								<tr>
									<th v-for="(th, th_idx) in allowedSortedThead" :width="70" :key="th.key + '_' + th_idx">
										<template v-if="th.key != 'id'">
											{{ th.name }}
										</template>
									</th>
								</tr>
							</thead>
							<tbody>
								<template v-if="tbody.length">
									<tr v-for="(td, td_idx) in tbody" :key="'server_' + td_idx">
										<template v-for="(th, th_idx) in allowedSortedThead">
											<td :key="'server_data_$_' + th.key + '_' + th_idx" @click="routeToDetail(td, th)">
												<template v-if="th.key == 'action'">
													<div class="d-flex justify-space-around align-center">
														<v-tooltip top>
															<template #activator="{ on, attrs }">
																<v-icon
																	@click="doAction('update', td)"
																	size="20"
																	v-on="on"
																	v-bind="attrs"
																	class="cursor-pointer"
																	color="blue"
																	>mdi-pencil
																</v-icon>
															</template>
															<span>Edit Service Provider</span>
														</v-tooltip>
													</div>
												</template>

												<template v-else-if="th.key == 'type'">
													<v-chip label outlined color="red darken-4">{{ td.type }}</v-chip>
												</template>

												<template v-else-if="th.key == 'total_ssl'">
													<v-chip
														color="green"
														class="white--text"
														x-small
														label
														v-if="td.is_ssl == 1 && td.type == 'company'"
													>
														<v-icon small left>mdi-certificate</v-icon>
														{{ td.total_ssl }}
													</v-chip>
													<em class="text-muted" v-else>Not Provided</em>
												</template>

												<template v-else-if="th.key == 'total_domain'">
													<v-chip
														color="blue"
														class="white--text"
														x-small
														label
														v-if="td.is_domain == 1 && td.type == 'company'"
													>
														<v-icon small left>mdi-web</v-icon>
														{{ td.total_domain }}
													</v-chip>
													<em class="text-muted" v-else>Not Provided</em>
												</template>

												<template v-else-if="th.key == 'total_hosting'">
													<v-chip
														color="orange"
														class="white--text"
														x-small
														label
														v-if="td.is_hosting == 1 && td.type == 'company'"
													>
														<v-icon small left>mdi-content-save</v-icon>
														{{ td.total_hosting }}
													</v-chip>
													<em class="text-muted" v-else>Not Provided</em>
												</template>

												<template v-else-if="th.key == 'total_server'">
													<v-chip
														color="red"
														class="white--text"
														x-small
														label
														v-if="td.is_hosting == 1 && td.type == 'company'"
													>
														<v-icon small left>mdi-server</v-icon>
														{{ td.total_server }}
													</v-chip>
													<em class="text-muted" v-else>Not Provided</em>
												</template>

												<template v-else-if="th.key == 'activated'">
													<v-chip
														class="text-capitalize white--text"
														:color="td.activated == 1 ? 'green' : 'red'"
													>
														{{ td.activated == 1 ? "Active" : "In-Active" }}
													</v-chip>
												</template>

												<template v-else-if="th.key == 'added_at'">
													<div class="">
														<div class="d-flex justify-space-between">
															<v-chip class="mb-1" outlined label x-small color="purple">
																<v-icon small left>mdi-calendar</v-icon>
																{{ formatDate(td.added_at) }}
															</v-chip>
														</div>
													</div>
												</template>
												<template v-else>
													<ShowValue :object="td" :object-key="th.key" :label="th.name"> </ShowValue>
												</template>
											</td>
										</template>
									</tr>
								</template>
								<template v-else>
									<tr>
										<td colspan="9">
											<p class="m-0 row-not-found text-center py-3">
												<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
												Uhh... There <span class="text-lowercase">are no service provider at the moment.</span>
											</p>
										</td>
									</tr>
								</template>
							</tbody>
						</table>
					</div>
				</div>
				<v-row class="my-2">
					<v-col md="6" class="">
						<p class="m-0">{{ showingString }}</p>
					</v-col>
					<v-col md="6" class="text-right">
						<v-pagination
							color="blue darken-4"
							v-model="page"
							:length="totalPage"
							total-visible="9"
							class="listing-pagination"
							@input="updatePagination"
						></v-pagination>
					</v-col>
				</v-row>
			</template>
			<!-- </v-layout> -->
		</div>
		<AddServiceProvider
			v-if="addServiceProviderDialog"
			:dialog="addServiceProviderDialog"
			@close="(addServiceProviderDialog = false), (serviceProviderId = 0)"
			@success="getListing()"
			:service-provider-id="serviceProviderId"
		/>
	</div>
</template>
<script>
import objectPath from "object-path";
import AddServiceProvider from "@/view/components/AddServiceProvider.vue";
import ListingMixin from "@/core/mixins/listing.mixin";
import HeightMixin from "@/core/mixins/height.mixin";
import { mapGetters } from "vuex";
import { SET_CURRENT_PAGE } from "@/core/services/store/listing.module";
import ShowValue from "@/view/components/ShowValue";
import ServiceProviderDetail from "@/view/module/setting/ServiceProviderDetail";

export default {
	name: "ServerSettings",
	mixins: [ListingMixin, HeightMixin],
	components: {
		AddServiceProvider,
		ShowValue,
		ServiceProviderDetail,
	},
	data() {
		return {
			skipBottomHeight: 57,
			page: 1,
			type: "company",
			addServiceProviderDialog: false,
			endpoint: "service-provider",
			serviceProviderId: 0,
			isDetail: false,
			detail_data: null,
			defaultFilter: {
				type: "company",
			},
		};
	},
	watch: {
		"$route.query": {
			deep: true,
			handler(route) {
				if (objectPath.get(route, "tab") == "service_provider") {
					if (!objectPath.has(route, "detailId")) {
						this.isDetail = false;
						this.getListing();
					} else {
						this.isDetail = true;
					}
				}
				this.type = "company";
			},
		},
	},
	methods: {
		hasKey(object, key) {
			return objectPath.has(object, key);
		},
		getValue(object, key) {
			return objectPath.get(object, key);
		},
		goBack() {
			this.$router.go(-1);
		},
		updatePagination(param) {
			if (this.currentPage == param) {
				return false;
			}
			this.$store.commit(SET_CURRENT_PAGE, param);
			this.$nextTick(() => {
				this.filterRows();
			});
		},
		typeFilterRows(data) {
			this.defaultFilter.type = data;
			this.$nextTick(() => {
				this.getListing();
			});
		},
		routeToDetail(td, th) {
			if (th.checkbox || th.key == "action" || td.type == "client") {
				return false;
			}
			this.$router.push({
				path: "setting",
				query: { tab: "service_provider", detailId: td.id },
				// params: { id: td.id },
			});
			this.isDetail = true;
		},
		doAction(type, td) {
			switch (type) {
				case "update":
					this.serviceProviderId = td.id;
					this.addServiceProviderDialog = true;
					break;
			}
		},
	},
	computed: {
		...mapGetters([
			"selected",
			"tbody",
			"count",
			"currentPage",
			"currentUser",
			"totalPage",
			"showingString",
			"thead",
			"sortedThead",
		]),
		serverTab: {
			set(val) {
				let query = { ...this.$route.query };
				query.subTab = val;
				if (val != this.serverTab) {
					this.$router.replace({ query });
				}
			},
			get() {
				return this.$route.query.subTab || "renew";
			},
		},
	},
};
</script>
