<template>
	<div class="">
		<template v-if="pageLoading">
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
		<div v-else class="v-data-table bt-table theme--light">
			<div class="v-data-table__wrapper table_height" style="height: calc(100vh - 269px)">
				<table class="w-100">
					<thead>
						<tr>
							<th>SR. No.</th>
							<th>Type</th>
							<th>Server</th>
							<th>Cpanel/Server URL</th>
							<th>Duration</th>
							<th>Start Date</th>
							<th>End Date</th>
							<th>Remark</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="tbody.length">
							<tr v-for="(row, td_idx) in tbody" :key="'ssl_' + row.id + '_' + td_idx">
								<td>{{ td_idx + 1 }}</td>
								<td>
									<Chip
										v-if="row.type == 'bthrust'"
										class="text-capitalize"
										:text="row.type"
										color="green"
										text-color=""
										outlined
									></Chip>
									<Chip
										v-if="row.type == 'client'"
										class="text-capitalize"
										:text="row.type"
										color="red"
										text-color=""
										outlined
									></Chip>
								</td>
								<td class="p-2 border-top-light-grey custome-table-td">
									<span v-if="row && row.server_name">
										{{ mod_string(row.server_name, "-") }}
									</span>
									<em class="text-muted" v-else>no server</em>
								</td>
								<td class="p-2 border-top-light-grey custome-table-td" style="max-width: 35rem">
									<div v-if="row && row.cpanal_url">{{ row.cpanal_url }}</div>
									<em class="text-muted" v-else>no cpanel url</em>
								</td>
								<td class="p-2 border-top-light-grey custome-table-td">
									<span v-if="row && row.duration"> {{ row.duration }}(Month) </span>
									<em class="text-muted" v-else>no duration</em>
								</td>
								<td class="p-2 border-top-light-grey custome-table-td">
									<span v-if="row && row.start_date">
										{{ formatDate(row.start_date) }}
									</span>
									<em class="text-muted" v-else>no start date</em>
								</td>
								<td class="p-2 border-top-light-grey custome-table-td">
									<span v-if="row && row.end_date">
										{{ formatDate(row.end_date) }}
									</span>
									<em class="text-muted" v-else>no end date</em>
								</td>
								<td class="p-2 border-top-light-grey custome-table-td" style="max-width: 25rem">
									<span v-if="row && row.remark">
										{{ row.remark }}
									</span>
									<em class="text-muted" v-else>no remark</em>
								</td>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td :colspan="8">
									<p class="m-0 row-not-found text-center py-3">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There <span class="text-lowercase">are no hosting at the moment.</span>
									</p>
								</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
		</div>
		<v-row class="my-2" v-if="tbody.length">
			<v-col md="6" class="">
				<p class="m-0">{{ showingString }}</p>
			</v-col>
			<v-col md="6" class="text-right">
				<v-pagination
					color="blue darken-4"
					v-model="page"
					:length="totalPage"
					total-visible="9"
					class="listing-pagination"
					@input="updatePagination"
				></v-pagination>
			</v-col>
		</v-row>
	</div>
	<!-- </v-sheet> -->
</template>
<script>
import MomentJS from "moment-timezone";
import { mapGetters } from "vuex";
import { SET_CURRENT_PAGE, GET_LISTING } from "@/core/services/store/listing.module";
import HeightMixin from "@/core/mixins/height.mixin";
import objectPath from "object-path";
import Chip from "@/view/components/Chip";

export default {
	name: "SSL",
	mixins: [HeightMixin],
	props: {
		internalType: {
			type: String,
			default: null,
		},
		internalValue: {
			type: [String, Number],
			default: null,
		},
	},
	data() {
		return {
			skipBottomHeight: 56,
			pageTitle: "SSLs",
			page: 1,
			status: null,
			sslList: [],
			project_status: {
				1: { title: "Draft", color: "cyan" },
				2: { title: "Completed", color: "green" },
				3: { title: "Renewed", color: "orange" },
				4: { title: "Cancelled", color: "red" },
			},
			pageLoading: true,
		};
	},
	methods: {
		getListingData() {
			this.pageLoading = true;
			const filter = new Object({
				tz: new Date().getTime(),
				page: this.currentPage ? this.currentPage : null,
			});
			if (this.internalType && this.internalValue) {
				filter[this.internalType] = this.internalValue;
			}

			this.$store
				.dispatch(GET_LISTING, { url: "service-provider-hosting", filter })
				.then(() => {
					setTimeout(() => {
						this.calculateHeight();
					}, 500);
				})
				.catch((error) => {
					console.error({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getValue(object, path) {
			return objectPath.get(object, path);
		},
		routeToDetail(th, id) {
			if (th.checkbox || th.key == "action") {
				return false;
			} else {
				let filterQuery = this.$route.query;
				this.$router.push({
					name: "ssl-detail",
					params: { id },
					query: { ...filterQuery, t: new Date().getTime() },
				});
			}
		},
		date_difference(start, end) {
			let start_Date = MomentJS(start);
			let end_Date = MomentJS(end);
			let totalDays = end_Date.diff(start_Date, "days");
			return totalDays;
		},
		updatePagination(param) {
			if (this.currentPage == param) {
				return false;
			}
			this.$store.commit(SET_CURRENT_PAGE, param);
			this.$nextTick(() => {
				this.getListingData();
			});
		},
	},
	computed: {
		...mapGetters([
			"selected",
			"tbody",
			"count",
			"currentPage",
			"currentUser",
			"totalPage",
			"showingString",
			"thead",
			"sortedThead",
		]),
	},
	components: {
		Chip,
	},
	mounted() {
		this.getListingData();
	},
};
</script>
