<template>
	<div>
		<div class="p-sticky bg-white">
			<v-layout class="align-center light-blue-bg px-2 flex-wrap">
				<v-col cols="12">
					<v-row class="mt-2">
						<v-col cols="4">
							<v-row>
								<v-col cols="4" style="padding: 0px !important">
									<span class="fw-600">Provider Name : </span>
								</v-col>
								<v-col cols="8" style="padding: 0px !important">
									<span
										v-if="getValue(detail_data, 'name')"
										class="mb-0 fw-600 text-capitalize"
										style="font-size: 13px"
									>
										{{ getValue(detail_data, "name") }}
									</span>
									<em class="text-muted" v-else>no service provider</em>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="4">
							<v-row>
								<v-col cols="4" style="padding: 0px !important">
									<span class="fw-600">Total SSL: </span>
								</v-col>
								<v-col cols="8" style="padding: 0px !important">
									<v-chip
										color="green"
										class="white--text"
										x-small
										label
										v-if="detail_data && detail_data.is_ssl == 1"
									>
										<v-icon small left>mdi-certificate</v-icon>
										{{ detail_data.total_ssl }}
									</v-chip>
									<em class="text-muted" v-else>Not Provided</em>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="4">
							<v-row>
								<v-col cols="4" style="padding: 0px !important">
									<span class="fw-600">Total Domain: </span>
								</v-col>
								<v-col cols="8" style="padding: 0px !important">
									<v-chip
										color="blue"
										class="white--text"
										x-small
										label
										v-if="detail_data && detail_data.is_domain == 1"
									>
										<v-icon small left>mdi-web</v-icon>
										{{ detail_data.total_domain }}
									</v-chip>
									<em class="text-muted" v-else>Not Provided</em>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="4">
							<v-row>
								<v-col cols="4" style="padding: 0px !important">
									<span class="fw-600">Type: </span>
								</v-col>
								<v-col cols="8" style="padding: 0px !important">
									<span
										v-if="getValue(detail_data, 'type')"
										class="mb-0 fw-600 text-capitalize"
										style="font-size: 13px"
									>
										<v-icon size="22">{{
											getValue(detail_data, "type") == "company" ? "mdi-domain" : "mdi-account"
										}}</v-icon>
										{{ getValue(detail_data, "type") }}
									</span>
									<!-- </v-chip> -->
									<em class="text-muted" v-else>no type</em>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="4">
							<v-row>
								<v-col cols="4" style="padding: 0px !important">
									<span class="fw-600">Total Hosting: </span>
								</v-col>
								<v-col cols="8" style="padding: 0px !important">
									<v-chip
										color="orange"
										class="white--text"
										x-small
										label
										v-if="detail_data && detail_data.is_hosting == 1"
									>
										<v-icon small left>mdi-content-save</v-icon>
										{{ detail_data.total_hosting }}
									</v-chip>
									<em class="text-muted" v-else>Not Provided</em>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="4">
							<v-row>
								<v-col cols="4" style="padding: 0px !important">
									<span class="fw-600">Total Server: </span>
								</v-col>
								<v-col cols="8" style="padding: 0px !important">
									<v-chip
										color="red"
										class="white--text"
										x-small
										label
										v-if="detail_data && detail_data.is_hosting == 1"
									>
										<v-icon small left>mdi-server</v-icon>
										{{ detail_data.total_server }}
									</v-chip>
									<em class="text-muted" v-else>Not Provided</em>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
					<v-row class="mt-2">
						<v-col cols="4">
							<v-row>
								<v-col cols="4" style="padding: 0px !important">
									<span class="fw-600">Website : </span>
								</v-col>
								<v-col cols="8" style="padding: 0px !important">
									<span
										v-if="getValue(detail_data, 'website')"
										class="mb-0 fw-600"
										style="font-size: 13px"
										>{{ detail_data?.website }}</span
									>
									<!-- </v-chip> -->
									<em class="text-muted" v-else>no website</em>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-col>
			</v-layout>
			<v-layout class="pa-2 pb-0">
				<v-tabs
					v-model="servicesTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent w-100"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#ssl" v-if="detail_data && detail_data.is_ssl"
						><v-icon small left>mdi-certificate</v-icon>SSL</v-tab
					>
					<v-tab href="#domain" v-if="detail_data && detail_data.is_domain"
						><v-icon small left>mdi-web</v-icon>Domain</v-tab
					>
					<v-tab href="#hosting" v-if="detail_data && detail_data.is_hosting"
						><v-icon small left>mdi-content-save</v-icon>Hosting</v-tab
					>
					<v-tab href="#server" v-if="detail_data && detail_data.is_hosting"
						><v-icon small left>mdi-server</v-icon>Server</v-tab
					>
				</v-tabs>
			</v-layout>
		</div>
		<div class="tab-layout-content project-tb-content">
			<v-tabs-items v-model="servicesTab">
				<v-tab-item v-if="servicesTab == 'ssl'" value="ssl" class="">
					<SSLInternalListing
						internal-type="service_provider"
						:internal-value="detail_data ? detail_data.name : null"
					/>
				</v-tab-item>
				<v-tab-item v-if="servicesTab == 'domain'" value="domain" class="">
					<DomainInternalListing
						internal-type="service_provider"
						:internal-value="detail_data ? detail_data.name : null"
					/>
				</v-tab-item>
				<v-tab-item v-if="servicesTab == 'hosting'" value="hosting" class="">
					<HostingListing
						internal-type="service_provider"
						:internal-value="detail_data ? detail_data.name : null"
					/>
				</v-tab-item>
				<v-tab-item v-if="servicesTab == 'server'" value="server" class="">
					<ServerInternalListing
						internal-type="service_provider"
						:internal-value="detail_data ? detail_data.name : null"
					/>
				</v-tab-item>
			</v-tabs-items>
		</div>
	</div>
</template>
<script>
import { GET } from "@/core/services/store/request.module";
import objectPath from "object-path";
import HeightMixin from "@/core/mixins/height.mixin";
import SSLInternalListing from "@/view/components/SSLInternalListing";
import DomainInternalListing from "@/view/components/DomainInternalListing";
import HostingListing from "@/view/components/HostingListing";
import ServerInternalListing from "@/view/components/ServerInternalListing";

export default {
	name: "ServerSettings",
	mixins: [HeightMixin],
	data() {
		return {
			skipBottomHeight: 57,
			page: 1,
			status: null,
			serverId: null,
			isDetail: false,
			detail_data: null,
			servicesTab: "",
		};
	},
	components: {
		SSLInternalListing,
		DomainInternalListing,
		HostingListing,
		ServerInternalListing,
	},
	watch: {
		"$route.query": {
			deep: true,
			handler(route) {
				if (objectPath.has(route, "detailId") && objectPath.get(route, "tab") == "service_provider") {
					let id = objectPath.get(route, "detailId");
					id && this.getServiceProviderById(id);
				}
			},
		},
	},
	methods: {
		hasKey(object, key) {
			return objectPath.has(object, key);
		},
		getValue(object, key) {
			return objectPath.get(object, key);
		},
		goBack() {
			this.$router.go(-1);
		},
		getServiceProviderById(id) {
			this.pageLoading = true;
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: `detailed-service-provider/${id}`,
				})
				.then((data) => {
					this.detail_data = data;
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	mounted() {
		let query = this.$route.query;
		if (objectPath.has(query, "detailId")) {
			let detail_id = objectPath.get(query, "detailId");
			this.isDetail = true;
			this.getServiceProviderById(detail_id);
		}
	},
};
</script>
