<template>
	<v-img
		:id="id"
		:src="imgUrl"
		:lazy-src="lazySrc"
		:aspect-ratio="aspectRatio"
		contain
		class="white lighten-2"
		:class="{ ...customClass, 'btx-image-circle': circle }"
	>
		<template v-slot:placeholder>
			<v-row class="fill-height ma-0" align="center" justify="center">
				<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
			</v-row>
		</template>
	</v-img>
</template>

<script>
export default {
	name: "image-template",
	props: {
		aspectRatio: {
			type: Number,
			default: 1,
		},
		customClass: {
			type: String,
			default: null,
		},
		lazySrc: {
			type: String,
			default: process.env.VUE_APP_BASE_URL + "/media/misc/no_photo_found.png",
		},
		src: {
			type: String,
			default: process.env.VUE_APP_BASE_URL + "/media/misc/no_photo_found.png",
		},
		apiSrc: {
			type: String,
			default: null,
		},
		circle: {
			type: Boolean,
			default: false,
		},
		id: {
			type: [String, Number],
			default: undefined,
		},
	},
	watch: {
		apiSrc() {
			this.init();
		},
		src() {
			this.init();
		},
	},
	data() {
		return {
			imageURL: process.env.VUE_APP_BASE_URL + "/media/misc/no_photo_found.png",
		};
	},
	computed: {
		imgUrl: {
			get() {
				let img;
				if (this.apiSrc) {
					img = process.env.VUE_APP_API_URL + "file-manager/" + this.apiSrc;
				} else if (this.src) {
					img = this.src;
				}
				return img || process.env.VUE_APP_BASE_URL + "/media/misc/no_photo_found.png";
			},
		},
	},
	methods: {
		init() {
			if (this.apiSrc) {
				this.imageURL = process.env.VUE_APP_API_URL + "file-manager/" + this.apiSrc;
				return false;
			}
			if (this.src) {
				this.imageURL = this.src;
				return false;
			}
		},
	},
	mounted() {
		this.init();
	},
};
</script>
